import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import HeaderAdmin from "../navBar/HeaderAdmin/HeaderAdmin";
import SidebarAdmin from "../navBar/SidebarAdmin/SidebarAdmin";
import "./AdminLayout.css";
import { Toaster } from "react-hot-toast";
import TimeOutLogin from "../../../utils/TimeOutLogin";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { onlogout } from "../../../redux/slice/User.slice";

const AdminLayout = () => {
  const dispatch = useAppDispatch();
  const timer = TimeOutLogin(60 * 10);
  const { user, token } = useAppSelector((s) => s?.user);
  useEffect(() => {
    if (token && user?.id) {
      let { exp } = jwtDecode(token);
      if (exp < moment().unix()) {
        dispatch(onlogout());
      }
    }
  });
  useEffect(() => {
    if (timer === 0 && token) {
      return onLogout();
    }
  }, [token, timer]);

  const onLogout = () => {
    Swal.fire({
      title: `Session expirée!`,
      text: "Veuillez vous reconnecter",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      iconColor: Color.danger,
      timer: 3000,
    }).then(() => {
      dispatch(onlogout());
    });
  };
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <SidebarAdmin />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-0 h-90vh position-relative page-content-base-container">
          <div className="admin-page-container">
            <Outlet />
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default AdminLayout;
