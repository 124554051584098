import React from "react";
import UsersWithoutProfileTable from "./UsersWithoutProfileTable";

function UsersWithoutProfile() {
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <div className="crypta-dash-locataire-requetes-header">
            <h4 className="crypta-dash-header-titre">
              Utilisateurs sans profil
            </h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="">
            <UsersWithoutProfileTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersWithoutProfile;
