import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { cleannerError, getName, onHide } from "../../../../../utils/utils";

import { QueryError } from "../../../../../utils/type";
import { toast } from "react-hot-toast";
import {
  AbonnementFormData,
  IAbonnements,
} from "../../../../../utils/api/abonnement/abonnement.type";
import { useGetPatientsQuery } from "../../../../../utils/api/user/user.api";
import { useAddOrEditAbonnementMutation } from "../../../../../utils/api/abonnement/abonnement.api";
import { useGetOffresQuery } from "../../../../../utils/api/offres/offres.api";
import moment from "moment";
import { useGetModePaiementQuery } from "../../../../../utils/api/config/config.api";
import { useAppSelector } from "../../../../../redux/hooks";

function useCrudAbonnement(
  resetItem: () => void,
  modalId: string,
  item?: IAbonnements
) {
  const validationSchema = yup.object().shape({
    user: yup
      .number()
      .required()
      .label("Patient")
      .typeError("Patient est un champ obligatoire"),
    offre: yup
      .number()
      .required()
      .label("Offre")
      .typeError("Offre est un champ obligatoire"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<AbonnementFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addSubscription, { isLoading }] = useAddOrEditAbonnementMutation();
  const [inputValue, setInputValue] = useState("");
  const { data = { results: [] } } = useGetPatientsQuery({ q: inputValue });
  const { data: Offres = { results: [] } } = useGetOffresQuery({ limit: 100 });
  const { data: modes = { results: [] } } = useGetModePaiementQuery({
    limit: 100,
  });

  const { user } = useAppSelector((s) => s?.user);
  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<
    { label: string; value: number } | undefined
  >();
  const [offer, setOffer] = useState<
    { label: string; value: number } | undefined
  >();
  const [mode, setMode] = useState<
    { label: string; value: number } | undefined
  >();

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    const modeValue = modes?.results?.filter((item) => item?.nom === "cash")[0];
    if (modeValue) {
      setMode({
        label: modeValue?.nom,
        value: modeValue?.id,
      });
      setValue("moyen_paiement", modeValue?.id as any);
    }
  }, [modes]);

  useEffect(() => {
    if (item?.id) {
      let fields: (keyof AbonnementFormData)[] = [
        "date_debut",
        "date_fin",
        "offre",
        "user",
        "moyen_paiement",
      ];
      for (let field of fields) {
        register(field);
        if (
          field !== "offre" &&
          field !== "user" &&
          field !== "moyen_paiement" &&
          field !== "created_by"
        ) {
          setValue(field, item[field]);
        } else {
          setValue(field, item[field]?.id as any);
        }
      }
	  setStartDate(new Date(item?.date_debut))
	  setEndDate(new Date(item?.date_fin))
      setOption({
        label: getName(item?.user),
        value: item?.user?.id,
      });
      setOffer({
        label: item?.offre?.title,
        value: item?.offre?.id,
      });
      setMode({
        label: item?.moyen_paiement?.nom,
        value: item?.moyen_paiement?.id,
      });
    }
  }, [item]);

  const handleChangeStart = (date) => {
	setValue("date_debut",date)
	setStartDate(date)
  }
  const handleChangeEnd = (date) => {
	setValue("date_fin",date)
	setEndDate(date)
  }

  const handleChange = (selectedOption: any) => {
    setValue("user", selectedOption?.value);
    setOption(selectedOption);
  };
  const handleSelectOffer = (selectedOption: any) => {
    setValue("offre", selectedOption?.value);
    setOffer(selectedOption);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const filterOptions = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const loadOptions = (inputValue) => {
    if (inputValue.length < 2) {
      let options = data?.results?.map((item) => {
        return {
          label: getName(item),
          value: item?.id,
        };
      });
      setOptions(options);
      return;
    }

    setTimeout(() => {
      const filteredOptions = options.filter((option) =>
        filterOptions(option, inputValue)
      );
      setOptions(filteredOptions);
    }, 300);
  };

  const onSubmit = async (data: AbonnementFormData) => {
    const mode = modes?.results?.filter((item) => item?.nom === "cash")[0];
    let newData: AbonnementFormData = {
      ...data,
    };
    if (!item) {
      const newData: AbonnementFormData = {
        ...data,
        date_debut: moment(new Date()).format("YYYY-MM-DDThh:mm"),
        created_by: user?.id,
      };
    }

    console.log({ newData });

    const res = await addSubscription({
      data: newData,
      slug: item?.slug,
    });

    if ("data" in res) {
      const msg = `Abonnement ${!item ? "ajouté" : "modifié"} avec succès`;
      console.log("data", data);
      toast.success(msg);
      reset();
      resetItem();
      onHide(modalId);
      setInputValue("");
      setOptions([]);
    }
    if ("error" in res) {
      const err = res.error as QueryError;
      let mes = err?.data?.message
        ? err?.data?.message
        : `Erreur de statut ${err?.status}`;
      toast.error(mes);
    }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading: isLoading,
    patients: data?.results?.map((item) => {
      return {
        label: getName(item),
        value: item?.id,
      };
    }),
    handleChange,
    filterOptions,
    inputValue,
    handleInputChange,
    loadOptions,
    options,
    offres: Offres?.results?.map((item) => {
      return {
        label: item?.title,
        value: item?.id,
      };
    }),
    modes: modes?.results?.map((item) => {
      return {
        label: item?.titre,
        value: item?.id,
      };
    }),
    modeValue: modes?.results?.map((item) => {
      if (item?.nom === "cash") {
        return {
          label: item.titre,
          value: item.id,
        };
      }
    }),
    handleSelectOffer,
    option,
    mode,
    offer,
	endDate,
	startDate,
	handleChangeEnd,
	handleChangeStart
  };
}

export default useCrudAbonnement;
