export type FieldType =
  | "dashboard"
  | "dossiers"
  | "faqs"
  | "forum"
  | "messages"
  | "parametres"
  | "professionnels"
  | "remboursements"
  | "transactions"
  | "patients"
  | "demandes"
  | "offres"
  | "signalements"
  | "landing_page"
  | "abonnements" | "utilisateurs_sans_profil";
type Authorization = {
  field: FieldType;
  label: string;
  path: string;
};
export const Authorisations: Authorization[] = [
  {
    field: "dashboard",
    label: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    field: "patients",
    label: "Patients",
    path: "/admin/patients",
  },
  {
    field: "transactions",
    label: "Transactions",
    path: "/admin/transactions",
  },
  {
    field: "professionnels",
    label: "Professionnels",
    path: "/admin/professionnels",
  },
  {
    field: "utilisateurs_sans_profil",
    label: "Utilisateurs sans profil",
    path: "/admin/utilisateurs-sans-profil",
  },
  {
    field: "forum",
    label: "Forum",
    path: "/admin/forum",
  },
  {
    field: "remboursements",
    label: "Remboursements",
    path: "/admin/remboursements",
  },
  {
    field: "messages",
    label: "Messages",
    path: "/admin/messages",
  },
  {
    field: "dossiers",
    label: "Dossiers",
    path: "/admin/dossiers",
  },
  {
    field: "demandes",
    label: "Demandes",
    path: "/admin/demandes",
  },
  {
    field: "offres",
    label: "Offres",
    path: "/admin/offres",
  },
  {
    field: "abonnements",
    label: "Abonnements",
    path: "/admin/abonnements",
  },
  {
    field: "signalements",
    label: "Signalements",
    path: "/admin/signalements",
  },
  {
    field: "landing_page",
    label: "Landing page",
    path: "/admin/landing_page",
  },
  {
    field: "parametres",
    label: "Paramètres",
    path: "/admin/parametres",
  },
];

export const ContactMessageStatus = [
  {
    value: "new",
    label: "Nouveau",
  },
  {
    value: "in_progress",
    label: "En cours",
  },
  {
    value: "traited",
    label: "Traité",
  },
];
export const imageExt = ["jpg", "jpeg", "png", "gif", "bmp"];
export const maxAMountRemboursement = 1000000;
export const alphaRegex = /^[[A-Za-zÀ-ÿ]*[A-Za-zÀ-ÿ][A-Za-zÀ-ÿ\s]+$/;
export const alphaSpaceRegex = /^[[A-Za-zÀ-ÿ]*[A-Za-zÀ-ÿ][A-Za-zÀ-ÿ\s]+$/;
export const UPLOAD_FILE_END_POINT =
  "https://hub-services.volkeno-engineering.click/api/v1/app/upload-file";
