type ENV = 'dev' | 'prod' | 'demo'

export const currentEnv: ENV = "dev";

const env: ENV = currentEnv;

export const Env = env

const API_DEV_URL = "https://crypta-back.withvolkeno.com";
const API_DEMO_URL = "https://crypta-back.withvolkeno.com";
const API_PROD_URL = "https://guinaw.kivu.sn";

export const APP_URL = "kivu.sn";

function processApiUrl() {
	if (env === "prod") return API_PROD_URL;
	if (env === "demo") return API_DEMO_URL;
	return API_DEV_URL;
}
export const ApiBaseUrl = processApiUrl();

export const GoogleApikey = "AIzaSyAbxLTcHU4SOIP6ghEPHh-uI63j-2LRr2g";

export const GoogleClientID =
	"892682149131-5rhlnh3pcni2e74qh0s29ii6hqanndtp.apps.googleusercontent.com";

export const FacebookAppID = "469491841385404"
