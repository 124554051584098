import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ISignalements, SignalementFormData } from "./signalements.type";
import { prepareHeaders } from "../user/user.api";

export const SignalementsApi = createApi({
	reducerPath: "signalementsApi",
	tagTypes: ["signalements"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getSignalements: builder.query<
			PaginationResults<ISignalements>,
			TypeQuery & { forum?: string; comment?: string; author?: string; user?: string }
		>({
			query: (query) => QueryUrl(`signalements/`, query),
			providesTags: ["signalements"],
		}),
		getSignalementsVisitor: builder.query<ISignalements[], void>({
			query: () => `signalement/visitors/`,
			providesTags: ["signalements"],
		}),
		addOrEditSignalement: builder.mutation<
			ISignalements,
			{ slug?: string; data: SignalementFormData }
		>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `signalements/${slug}/`,
						method: "PUT",
						body: data,
					};
				} else {
					return {
						url: `signalements/`,
						method: "POST",
						body: data,
					};
				}
			},
			invalidatesTags: ["signalements"],
		}),
		sendEditSignalementCode: builder.query<ISignalements, void>({
			query: () => ({
				url: `modification_signalement/request_code/`,
				method: "GET",
			}),
			// providesTags: ["signalements"],
		}),
		sendSignalementDeleteCode: builder.query<ISignalements, void>({
			query: () => ({
				url: `suppression_signalement/request_code/`,
				method: "GET",
			}),
			// providesTags: ["signalements"],
		}),
		deleteSignalement: builder.mutation<
			ISignalements,
			{ slug: string; data: { code: string } }
		>({
			query: ({ slug, data }) => ({
				url: `signalements/${slug}/`,
				method: "Delete",
				body: data,
			}),
			invalidatesTags: ["signalements"],
		}),
	}),
});
export const {
	useGetSignalementsQuery,
	useGetSignalementsVisitorQuery,
	useAddOrEditSignalementMutation,
	useDeleteSignalementMutation,
	useLazySendSignalementDeleteCodeQuery,
	useLazySendEditSignalementCodeQuery,
} = SignalementsApi;
