import React from "react";
import { IoBarChart } from "react-icons/io5";
import { FaHandHolding, FaUserClock, FaUsers } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import {
	BsFillChatDotsFill,
	BsFillFileEarmarkRuledFill,
	BsFillPersonFill,
	BsFillPersonPlusFill,
} from "react-icons/bs";
import { AiTwotoneSetting } from "react-icons/ai";
import { HiFolderOpen } from "react-icons/hi";
import { LogoutBtnSidebar } from "../HeaderAdmin/LogoutBtn";
import VersionChecker from "../../../versionChecker/VersionChecker";
import { MdReportProblem, MdSubscriptions } from "react-icons/md";
import { useAppSelector } from "../../../../redux/hooks";
import { isSuperAdmin } from "../../../../routes/routerUtils";
import { RiFileSettingsFill } from "react-icons/ri";

const links = [
	{
		icon: <IoBarChart />,
		link: "dashboard",
		name: "Dashboard",
	},
	{
		icon: <BsFillPersonPlusFill />,
		link: "patients",
		name: "Patients",
	},
	{
		icon: <BsFillFileEarmarkRuledFill />,
		link: "transactions",
		name: "Transactions",
	},
	{
		icon: <BsFillPersonFill />,
		link: "professionnels",
		name: "Professionnels",
	},
	{
		icon: <FaUserClock />,
		link: "utilisateurs-sans-profil",
		name: "Utilisateurs sans profil"
	},
	{
		icon: <FaUsers />,
		link: "forum",
		name: "Forum",
	},
	{
		icon: <BsFillFileEarmarkRuledFill />,
		link: "remboursements",
		name: "Remboursements",
	},
	{
		icon: <BsFillFileEarmarkRuledFill />,
		link: "demandes",
		name: "Demandes",
	},
	{
		icon: <BsFillChatDotsFill />,
		link: "messages",
		name: "Messages",
	},
	{
		icon: <HiFolderOpen />,
		link: "dossiers",
		name: "Dossiers",
	},
	{
		icon: <FaHandHolding />,
		link: "offres",
		name: "Offres",
	},
	{
		icon: <MdSubscriptions />,
		link: "abonnements",
		name: "Abonnements",
	},
	{
		icon: <MdReportProblem />,
		link: "signalements",
		name: "Signalements",
	},
	{
		icon: <RiFileSettingsFill />,
		link: "landing_page",
		name: "Landing page",
	},
	{
		icon: <AiTwotoneSetting />,
		link: "parametres",
		name: "Paramètres",
	},
];

export function MenuAdmin({ toggleMenu }: { toggleMenu?: () => void }) {
	const { user } = useAppSelector((s) => s?.user);
	// console.log({ user });
	const is_SuperAdmin = isSuperAdmin(user);
	return (
		<ul className="nav nav-items-custom flex-column">
			{links.map((item) => (
				<li
					className="nav-item nav-item-vertical-custom"
					key={item?.link}
					onClick={toggleMenu && toggleMenu}
					hidden={!user[item.link] && !is_SuperAdmin}
				>
					<NavLink
						className="nav-link nav-link-vertival-custom"
						to={`/admin/${item?.link}`}
					>
						{item?.icon}
						<span className="hiddenable-navlink-label">{item?.name}</span>
					</NavLink>
				</li>
			))}
			<li className="nav-item nav-item-vertical-custom">
				<LogoutBtnSidebar />
			</li>
			<li className="nav-item nav-item-vertical-custom mb-5">
				<span className="version-view-container">{"v" + VersionChecker()}</span>
			</li>{" "}
		</ul>
	);
}

const SidebaragenceContent: React.FC = () => {
	return (
		<div className="hoverable" id="scrollbar">
			<div className="content">
				<MenuAdmin />
			</div>
		</div>
	);
};

export default SidebaragenceContent;
