import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { IOffres, OffreFormData, OffreStatusFormData } from "./offres.type";
import { prepareHeaders } from "../user/user.api";

export const OffresApi = createApi({
	reducerPath: "offresApi",
	tagTypes: ["offres", "visitorsOffers","request"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getOffres: builder.query<PaginationResults<IOffres>, TypeQuery>({
			query: (query) => QueryUrl(`offres/`, query),
			providesTags: ["offres"],
		}),
		getOffresVisitor: builder.query<IOffres[], void>({
			query: () => `offre/visitors/`,
			providesTags: ["visitorsOffers"],
		}),
		addOrEditOffre: builder.mutation<IOffres, { slug?: string; data: OffreFormData }>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `offres/${slug}/`,
						method: "PUT",
						body: data,
					};
				} else {
					return {
						url: `offres/`,
						method: "POST",
						body: data,
					};
				}
			},
			invalidatesTags: ["offres", "visitorsOffers"],
		}),
		updateOffre: builder.mutation<
			IOffres,
			{ slug?: string; data: OffreFormData | FormData }
		>({
			invalidatesTags: ["offres", "visitorsOffers"],
			query: ({ slug, data }) => {
				return {
					url: `offre/${slug}/update_position/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		updateStatusOffre: builder.mutation<
			IOffres,
			{ slug?: string; data: OffreStatusFormData | FormData }
		>({
			invalidatesTags: ["offres", "visitorsOffers"],
			query: ({ slug, data }) => {
				return {
					url: `offre/${slug}/update_position/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		sendEditOfferCode: builder.query<IOffres, void>({
			query: () => ({
				url: `modification_offre/request_code/`,
				method: "GET",
			}),
			providesTags: ["request"],
		}),
		sendOfferDeleteCode: builder.query<IOffres, void>({
			query: () => ({
				url: `suppression_offre/request_code/`,
				method: "GET",
			}),
			providesTags: ["request"],
		}),
		deleteOffre: builder.mutation<IOffres, { slug: string; data: { code: string } }>({
			query: ({ slug, data }) => ({
				url: `offres/${slug}/`,
				method: "Delete",
				body: data,
			}),
			invalidatesTags: ["offres"],
		}),
	}),
});

export const {
	useGetOffresQuery,
	useGetOffresVisitorQuery,
	useAddOrEditOffreMutation,
	useDeleteOffreMutation,
	useLazySendOfferDeleteCodeQuery,
	useLazySendEditOfferCodeQuery,
	useUpdateOffreMutation,
	useUpdateStatusOffreMutation
} = OffresApi;
