import { configureStore, Middleware } from "@reduxjs/toolkit";
import { AdminApi } from "../utils/api/admin/admin.api";
import { AuthApi } from "../utils/api/auth/auth.api";
import { CandidatureApi } from "../utils/api/candidature/candidature.api";
import { ConditionApi } from "../utils/api/condition/condition.api";
import { ContactApi } from "../utils/api/contact/contact.api";
import { DemandeApi } from "../utils/api/demande/demande.api";
import { DossierApi } from "../utils/api/dossier/dossier.api";
import { ForumApi } from "../utils/api/forum/forum.api";
import { NewsletterApi } from "../utils/api/newsletter/newsletter.api";
import { NotificationApi } from "../utils/api/notification/notification.api";
import { OffresApi } from "../utils/api/offres/offres.api";
import { OrderApi } from "../utils/api/order/order.api";
import { RemboursementApi } from "../utils/api/remboursement/remboursement.api";
import { TransactionApi } from "../utils/api/transaction/transaction.api";
import { UserApi } from "../utils/api/user/user.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice, onlogout } from "./slice/User.slice";
import { SlideApi } from "../utils/api/slide/slide.api";
import { RdvApi } from "../utils/api/rdv/rdv.api";
import { SignalementsApi } from "../utils/api/signalements/signalements.api";
import { ConfigApi } from "../utils/api/config/config.api";
import { AbonnementsApi } from "../utils/api/abonnement/abonnement.api";
import { ScreenApi } from "../utils/api/screen/screen.api";
import Swal from "sweetalert2";

const authMiddleware: Middleware = (store) => (next) => (action) => {
  // console.log("action",action)
  if (
    action.type.endsWith("/rejected") &&
    action.payload?.originalStatus === 401
  ) {
    Swal.fire({
      icon: "error",
      title: "Votre session est expirée.",
      text: "Veuillez-vous reconnecter",
      timer: 4000,
      showConfirmButton: false,
    });
    store.dispatch(onlogout());
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [OffresApi.reducerPath]: OffresApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [NewsletterApi.reducerPath]: NewsletterApi.reducer,
    [CandidatureApi.reducerPath]: CandidatureApi.reducer,
    [TransactionApi.reducerPath]: TransactionApi.reducer,
    [AdminApi.reducerPath]: AdminApi.reducer,
    [RemboursementApi.reducerPath]: RemboursementApi.reducer,
    [DossierApi.reducerPath]: DossierApi.reducer,
    [ForumApi.reducerPath]: ForumApi.reducer,
    [ConditionApi.reducerPath]: ConditionApi.reducer,
    [DemandeApi.reducerPath]: DemandeApi.reducer,
    [NotificationApi.reducerPath]: NotificationApi.reducer,
    [SlideApi.reducerPath]: SlideApi.reducer,
    [RdvApi.reducerPath]: RdvApi.reducer,
    [SignalementsApi.reducerPath]: SignalementsApi.reducer,
    [ConfigApi.reducerPath]: ConfigApi.reducer,
    [AbonnementsApi.reducerPath]: AbonnementsApi.reducer,
    [ScreenApi.reducerPath]: ScreenApi.reducer,
  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    authMiddleware,
    AuthApi.middleware,
    UserApi.middleware,
    ContactApi.middleware,
    OffresApi.middleware,
    OrderApi.middleware,
    NewsletterApi.middleware,
    CandidatureApi.middleware,
    TransactionApi.middleware,
    AdminApi.middleware,
    RemboursementApi.middleware,
    DossierApi.middleware,
    ConditionApi.middleware,
    ForumApi.middleware,
    DemandeApi.middleware,
    NotificationApi.middleware,
    SlideApi.middleware,
    RdvApi.middleware,
    SignalementsApi.middleware,
    ConfigApi.middleware,
    AbonnementsApi.middleware,
    ScreenApi.middleware,
  ],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
