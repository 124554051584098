import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import {
  About,
  AboutData,
  Banner,
  BannerData,
  Diplome,
  DiplomeFormData,
  Echange,
  EchangeFormData,
  Faq,
  FaqFormData,
  GainFormData,
  Gains,
  ISocialMedia,
  JoinUs,
  JoinUsData,
  Landing,
  ModePaiment,
  ModePaimentFormData,
  SocialMediaFormData,
  Specialite,
  SpecialiteFormData,
} from "./config.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../utils";

export const ConfigApi = createApi({
  tagTypes: [
    "socialMedia",
    "faq",
    "home",
    "about",
    "join_us",
    "modepaiements",
    "diplomes",
    "specialites",
    "gains",
    "echanges",
  ],
  reducerPath: "configApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    updateSocialMedia: builder.mutation<
      ISocialMedia,
      { slug?: string; data: SocialMediaFormData }
    >({
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `reseaux_sociaux/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `reseaux_sociaux/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["socialMedia"],
    }),
    getSocialMedia: builder.query<ISocialMedia, void>({
      query: () => "reseaux_sociaux/",
      transformResponse: ({ results }) => results[0],
      providesTags: ["socialMedia"],
    }),
    getSocialMediaVisitor: builder.query<ISocialMedia, void>({
      query: () => "visitor/reseaux_sociaux/",
      providesTags: ["socialMedia"],
    }),
    getFaqs: builder.query<PaginationResults<Faq>, TypeQuery>({
      query: () => `/faqs/`,
      providesTags: ["faq"],
    }),
    getFaqForVisitors: builder.query<PaginationResults<Faq>, void>({
      query: () => `/visitor/faqs/`,
      providesTags: ["faq"],
    }),
    addOrUpdateFaq: builder.mutation<
      Faq,
      { slug?: string; data: FaqFormData | FormData }
    >({
      invalidatesTags: ["faq"],

      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `faqs/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `faqs/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteFaq: builder.mutation<Faq, string>({
      query: (slug) => ({
        url: `faqs/${slug}`,
        method: "DELETE",
      }),
      invalidatesTags: ["faq"],
    }),

    // Banner
    getBanner: builder.query<Banner, void>({
      query: () => "home/",
      providesTags: ["home"],
    }),
    updateBanner: builder.mutation<Banner, BannerData>({
      query: (data) => {
        return {
          url: `home/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["home"],
    }),

    // About
    getAbout: builder.query<About, void>({
      query: () => "about/",
      providesTags: ["about"],
    }),
    updateAbout: builder.mutation<About, AboutData>({
      query: (data) => {
        return {
          url: `about/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["about"],
    }),
    // JoinUs
    getJoinUs: builder.query<JoinUs, void>({
      query: () => "join_us/",
      providesTags: ["join_us"],
    }),
    updateJoinUs: builder.mutation<JoinUs, JoinUsData>({
      query: (data) => {
        return {
          url: `join_us/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["join_us"],
    }),

    // landing
    getLandingData: builder.query<Landing, void>({
      query: () => "landing_page/",
      providesTags: ["home", "about", "join_us"],
    }),

    // Mode de paiement
    getModePaiement: builder.query<PaginationResults<ModePaiment>, TypeQuery>({
      query: () => `/modepaiements/`,
      providesTags: ["modepaiements"],
    }),
    addOrUpdateModePaiement: builder.mutation<
      ModePaiment,
      {
        slug?: string;
        data: Partial<ModePaimentFormData> | FormData;
      }
    >({
      invalidatesTags: ["modepaiements"],

      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `modepaiements/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `modepaiements/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteModePaiement: builder.mutation<ModePaiment, string>({
      query: (slug) => ({
        url: `modepaiements/${slug}`,
        method: "DELETE",
      }),
      invalidatesTags: ["modepaiements"],
    }),

    // Diplome
    getDiplome: builder.query<PaginationResults<Diplome>, TypeQuery>({
      query: () => `/diplomes/`,
      providesTags: ["diplomes"],
    }),
    addOrUpdateDiplome: builder.mutation<
      Diplome,
      { slug?: string; data: DiplomeFormData | FormData }
    >({
      invalidatesTags: ["diplomes"],

      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `diplomes/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `diplomes/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteDiplome: builder.mutation<Diplome, string>({
      query: (slug) => ({
        url: `diplomes/${slug}`,
        method: "DELETE",
      }),
      invalidatesTags: ["diplomes"],
    }),

    // Specialite

    // Diplome
    getSpecialite: builder.query<PaginationResults<Specialite>, TypeQuery>({
      query: (query) => QueryUrl(`/specialites/`, query),
      providesTags: ["specialites"],
    }),
    addOrUpdateSpecialite: builder.mutation<
      Specialite,
      { slug?: string; data: SpecialiteFormData | FormData }
    >({
      invalidatesTags: ["specialites"],

      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `specialites/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `specialites/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteSpecialite: builder.mutation<Specialite, string>({
      query: (slug) => ({
        url: `specialites/${slug}`,
        method: "DELETE",
      }),
      invalidatesTags: ["specialites"],
    }),
    // Gains
    getGains: builder.query<Gains, void>({
      query: () => "gains/",
      providesTags: ["gains"],
    }),
    updateGains: builder.mutation<Gains, GainFormData>({
      query: (data) => {
        return {
          url: `gains/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["gains"],
    }),
    // Echanges
    getEchanges: builder.query<Echange, void>({
      query: () => "echanges/",
      providesTags: ["echanges"],
    }),
    updateEchanges: builder.mutation<Echange, EchangeFormData>({
      query: (data) => {
        return {
          url: `echanges/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["echanges"],
    }),
  }),
});

export const {
  useGetSocialMediaQuery,
  useUpdateSocialMediaMutation,
  useGetSocialMediaVisitorQuery,
  useGetFaqForVisitorsQuery,
  useGetFaqsQuery,
  useAddOrUpdateFaqMutation,
  useDeleteFaqMutation,
  useUpdateBannerMutation,
  useGetLandingDataQuery,
  useGetBannerQuery,
  useGetAboutQuery,
  useUpdateAboutMutation,
  useGetJoinUsQuery,
  useUpdateJoinUsMutation,
  useGetModePaiementQuery,
  useAddOrUpdateModePaiementMutation,
  useDeleteModePaiementMutation,
  useGetDiplomeQuery,
  useAddOrUpdateDiplomeMutation,
  useDeleteDiplomeMutation,
  useGetSpecialiteQuery,
  useAddOrUpdateSpecialiteMutation,
  useDeleteSpecialiteMutation,
  useGetEchangesQuery,
  useUpdateEchangesMutation,
  useGetGainsQuery,
  useUpdateGainsMutation,
} = ConfigApi;
